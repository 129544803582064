import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik';
import Select from 'react-select';

import * as ShopButtonStyles from '../shop/shopButton.module.css';
import * as AddressFormStyles from "./addressForm.module.css";

import { includeFullName } from '../../utils/stripeAllowedCountries';
import { BasketContext } from '../../utils/BasketContext';

const supportedCountries = includeFullName.map(country => ({
  value: country.ISO, label: country.fullName, color: '#ff6d00', isDisabled: false
}))
// ? https://react-select.com/styles#overriding-the-theme
const countrySelectorTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "var(--orange)",
    primary75: "var(--orange80)",
    primary50: "var(--orange60)",
    primary25: "var(--orange20)",
  },
})

const AddressForm = ({ onSubmit }) => {
  const [ selectedCountry, setSelectedCountry ] = useState(null)
  const [ countryError, setCountryError ] = useState(null)
  const [ formikInitialValues, setFormikInitialValues ] = useState({ name: '', address1: '', address2: '', postalCode: '', city: ''})
  const { setShipping, shipping } = useContext(BasketContext)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (shipping.address && shipping.address.country) {
      const country = supportedCountries.find(_c => _c.value === shipping.address.country.ISO)
      setSelectedCountry(country)
    }
    if (!!shipping.address) {
      setFormikInitialValues({
          name: shipping.address.name || '',
          address1: shipping.address.address1 || '',
          address2: shipping.address.address2 || '',
          postalCode: shipping.address.postalCode || '',
          city: shipping.address.city || '',
      })
    }
  }, [])

  const handleCountryChange = (country, { action }) => {
    if (action !== "select-option") {
      return
    }
    if (validateCountry(country)) {
      setShipping({ country: { ISO: country.value , name: country.label } })
      setSelectedCountry(country)
    }
  }

  const validateCountry = (country = selectedCountry) => {
    if (!country || !country.value) {
      setCountryError('Country is required');
      return false
    } else if (!supportedCountries.find(_c => _c.value === country.value)) {
      setCountryError('Country is not supported');
      return false
    } else {
      if (countryError) {
        setCountryError(null)
      }
      return true
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formikInitialValues}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Name is required';
        }
        if (!values.address1) {
          errors.address1 = 'Address is required';
        }
        if (!values.postalCode) {
          errors.postalCode = 'Postal code is required';
        }
        if (!values.city) {
          errors.city = 'Town or city is required';
        }

        const address = { ...values }
        if (validateCountry()) {
          address.country = {
            ISO: selectedCountry.value,
            name: selectedCountry.label
          }
        }
        setShipping(address)
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if (validateCountry()) {
          const address = {
            ...values,
            country: {
              ISO: selectedCountry.value,
              name: selectedCountry.label
            }
          }
          // * set shipping information inside basket context
          setShipping(address)
          onSubmit(address)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={AddressFormStyles.form}>

          <div
            className={[AddressFormStyles.field, (errors.name && touched.name) ? AddressFormStyles.fieldError : ""].join(" ")}
          >
            <input
              placeholder="Name"
              aria-label="Name"
              type="text"
              name="name"
              autoComplete="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {errors.name && touched.name && <span className={AddressFormStyles.errorMessage}>{errors.name}</span>}
          </div>

          <div
            className={[AddressFormStyles.field, (errors.address1 && touched.address1) ? AddressFormStyles.fieldError : ""].join(" ")}
          >
            <input
              placeholder="Address line 1"
              aria-label="Address line 1"
              type="text"
              name="address1"
              autoComplete="address-line1"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address1}
            />
            {errors.address1 && touched.address1 && <span className={AddressFormStyles.errorMessage}>{errors.address1}</span>}
          </div>

          <div
            className={AddressFormStyles.field}
          >
            <input
              placeholder="Address line 2"
              aria-label="Address line 2"
              type="text"
              name="address2"
              autoComplete="address-line2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address2}
            />
          </div>

          <div
            className={[AddressFormStyles.field, (errors.postalCode && touched.postalCode) ? AddressFormStyles.fieldError : ""].join(" ")}
          >
            <input
              placeholder="Postal code"
              aria-label="Postal code"
              type="text"
              name="postalCode"
              autoComplete="postal-code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postalCode}
            />
            {errors.postalCode && touched.postalCode && <span className={AddressFormStyles.errorMessage}>{errors.postalCode}</span>}
          </div>

          <div
            className={[AddressFormStyles.field, (errors.city && touched.city) ? AddressFormStyles.fieldError : ""].join(" ")}
          >
            <input
              placeholder="Town or city"
              aria-label="Town or city"
              type="text"
              name="city"
              autoComplete="address-level2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
            />
            {errors.city && touched.city && <span className={AddressFormStyles.errorMessage}>{errors.city}</span>}
          </div>

          <div
            className={[AddressFormStyles.field, AddressFormStyles.country, countryError ? AddressFormStyles.fieldError : ""].join(" ")}
          >
            <Select
              theme={countrySelectorTheme}
              onChange={handleCountryChange}
              isClearable={false}
              isSearchable={true}
              isMulti={false}
              placeholder="Country"
              aria-label="Country"
              name="country"
              type="text"
              defaultValue={null}
              value={selectedCountry}
              options={supportedCountries}
            />
            {countryError && <span className={AddressFormStyles.errorMessage}>{countryError}</span>}
          </div>

          <button className={ShopButtonStyles.shopButton} type="submit" disabled={isSubmitting}>
            GO TO PAYMENT
          </button>
        </form>
      )}
    </Formik>
  )
}

export default AddressForm