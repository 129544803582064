// ? ISO <-> Country name converter: https://www.tracemyip.org/tools/country-code-list-alpha-2-alhpa-3-converter/

const stripeAllowedCountries = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CH",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB",
  "AL",
  "AD",
  "AM",
  "BY",
  "BA",
  "FO",
  "GE",
  "GI",
  "IS",
  "IM",
  "LI",
  "MK",
  "MD",
  "MC",
  "ME",
  "RU",
  "RS",
  "TR",
  "UA",
]

export const includeFullName = [
  {
    fullName: "Austria",
    ISO: "AT",
  },
  {
    fullName: "Belgium",
    ISO: "BE",
  },
  {
    fullName: "Bulgaria",
    ISO: "BG",
  },
  {
    fullName: "Croatia",
    ISO: "HR",
  },
  {
    fullName: "Switzerland",
    ISO: "CH",
  },
  {
    fullName: "Cyprus",
    ISO: "CY",
  },
  {
    fullName: "Czech Republic",
    ISO: "CZ",
  },
  {
    fullName: "Denmark",
    ISO: "DK",
  },
  {
    fullName: "Estonia",
    ISO: "EE",
  },
  {
    fullName: "Finland",
    ISO: "FI",
  },
  {
    fullName: "France",
    ISO: "FR",
  },
  {
    fullName: "Germany",
    ISO: "DE",
  },
  {
    fullName: "Greece",
    ISO: "GR",
  },
  {
    fullName: "Hungary",
    ISO: "HU",
  },
  {
    fullName: "Ireland",
    ISO: "IE",
  },
  {
    fullName: "Italy",
    ISO: "IT",
  },
  {
    fullName: "Latvia",
    ISO: "LV",
  },
  {
    fullName: "Lithuania",
    ISO: "LT",
  },
  {
    fullName: "Luxembourg",
    ISO: "LU",
  },
  {
    fullName: "Malta",
    ISO: "MT",
  },
  {
    fullName: "Netherlands",
    ISO: "NL",
  },
  {
    fullName: "Norway",
    ISO: "NO",
  },
  {
    fullName: "Poland",
    ISO: "PL",
  },
  {
    fullName: "Portugal",
    ISO: "PT",
  },
  {
    fullName: "Romania",
    ISO: "RO",
  },
  {
    fullName: "Slovakia",
    ISO: "SK",
  },
  {
    fullName: "Slovenia",
    ISO: "SI",
  },
  {
    fullName: "Spain",
    ISO: "ES",
  },
  {
    fullName: "Sweden",
    ISO: "SE",
  },
  {
    fullName: "United Kingdom",
    ISO: "GB",
  },
  {
    fullName: "Albania",
    ISO: "AL",
  },
  {
    fullName: "Andorra",
    ISO: "AD",
  },
  {
    fullName: "Armenia",
    ISO: "AM",
  },
  {
    fullName: "Belarus",
    ISO: "BY",
  },
  {
    fullName: "Bosnia And Herzegovina",
    ISO: "BA",
  },
  {
    fullName: "Faroe Islands",
    ISO: "FO",
  },
  {
    fullName: "Georgia",
    ISO: "GE",
  },
  {
    fullName: "Gibraltar",
    ISO: "GI",
  },
  {
    fullName: "Iceland",
    ISO: "IS",
  },
  {
    fullName: "Isle Of Man",
    ISO: "IM",
  },
  {
    fullName: "Liechtenstein",
    ISO: "LI",
  },
  {
    fullName: "Macedonia",
    ISO: "MK",
  },
  {
    fullName: "Moldova",
    ISO: "MD",
  },
  {
    fullName: "Monaco",
    ISO: "MC",
  },
  {
    fullName: "Montenegro",
    ISO: "ME",
  },
  {
    fullName: "Russia",
    ISO: "RU",
  },
  {
    fullName: "Serbia",
    ISO: "RS",
  },
  {
    fullName: "Turkey",
    ISO: "TR",
  },
  {
    fullName: "Ukraine",
    ISO: "UA",
  },
]

export default stripeAllowedCountries